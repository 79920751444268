import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useAtom } from 'jotai';
import { useIntl } from 'react-intl';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Title from 'components/Title';
import AboutUsOffersNumbersItem from 'components/AboutUsOffersNumbersItem';
import AboutUsPartnersItem from 'components/AboutUsPartnersItem';
import AboutUsReinsuranceItem from 'components/AboutUsReinsuranceItem';
import { alternateLanguagesState } from '../atoms/languageSwitcher';
import collaborateurs from 'assets/images/collaborateurs.png';
import map from 'assets/images/map.svg';
import cake from 'assets/images/cake.svg';
import umbrella from 'assets/images/umbrella.svg';
import logoEmeria from 'assets/images/emeria.png';
import innerHTML from '../utils/innerHTML';
import { metaTitle } from '../utils/page-meta-utils';

const AboutUsPage = ({ data }) => {
  if (!data) return null;
  const document = data.prismicAboutPage;

  const intl = useIntl();
  const [alternateLanguages, setAlternateLanguages] = useAtom(
    alternateLanguagesState
  );

  useEffect(() => {
    setAlternateLanguages(document.alternate_languages);
  }, []);

  return (
    <Layout pageName="AboutUsPage">
      <Helmet>
        <title>
          {document.data.meta_title ||
            metaTitle(intl.formatMessage({ id: 'about.meta_title' }))}
        </title>
        <meta
          name="description"
          content={document.data.meta_description || ''}
        />
      </Helmet>
      <section className="AboutUsPage-numbers">
        <Title tag="h2" textAlign="center">
          {intl.formatMessage({ id: 'about.title' })}
        </Title>
        <Container size="small">
          <div className="AboutUsPage-numbersRow">
            <div className="AboutUsPage-numbersItem AboutUsPage-numbersItem--collaborateurs">
              <img src={collaborateurs} />
              <div className="AboutUsPage-numberText">
                <div className="AboutUsPage-numberValue">
                  {document.data.item_1_number}
                </div>
                <div
                  className="AboutUsPage-numberLabel"
                  {...innerHTML(document.data.item_1_label)}
                />
              </div>
            </div>
            <div className="AboutUsPage-verticalSeparator" />
            <div className="AboutUsPage-numbersItem AboutUsPage-numbersItem--sites">
              <img src={map} />
              <div className="AboutUsPage-numberText">
                <div className="AboutUsPage-numberValue">
                  {document.data.item_2_number}
                </div>
                <div
                  className="AboutUsPage-numberLabel"
                  {...innerHTML(document.data.item_2_label)}
                />
              </div>
            </div>
          </div>
          <div className="AboutUsPage-horizontalSeparator" />
          <div className="AboutUsPage-numbersRow">
            <div className="AboutUsPage-numbersItem AboutUsPage-numbersItem--anniversary">
              <img src={cake} />
              <div className="AboutUsPage-numberText">
                <div className="AboutUsPage-numberValue">
                  {document.data.item_3_number}
                </div>
                <div
                  className="AboutUsPage-numberLabel"
                  {...innerHTML(document.data.item_3_label)}
                />
              </div>
            </div>
            <div className="AboutUsPage-verticalSeparator" />
            <div className="AboutUsPage-secondRowColRight">
              <div className="AboutUsPage-numbersItem AboutUsPage-numbersItem--cases">
                <img src={umbrella} />
                <div className="AboutUsPage-numberText">
                  <div className="AboutUsPage-numberValue">
                    {document.data.item_4_number}
                  </div>
                  <div
                    className="AboutUsPage-numberLabel"
                    {...innerHTML(document.data.item_4_label)}
                  />
                </div>
              </div>
              <div className="AboutUsPage-horizontalSeparator" />
              <div className="AboutUsPage-baselineEmeria">
                {intl.formatMessage({ id: 'about.subsidiary_company' })}
              </div>
              <a class="Link Paragraph--center" href="https://emeria.eu" target="_blank" rel="noreferrer">
                <img src={logoEmeria} className="AboutUsPage-logoEmeria" />
              </a>
            </div>
          </div>
          <div className="AboutUsPage-horizontalSeparator AboutUsPage-offersNumbersSeparator" />
          <Title
            tag="h2"
            textAlign="center"
            className="AboutUsPage-offersNumbersTitle"
          >
            {intl.formatMessage({ id: 'about.offers_numbers' })}
          </Title>
          <ul className="AboutUsPage-offersNumbers">
            {document.data.offers_numbers.map((item, i) => (
              <AboutUsOffersNumbersItem
                key={`offers-numbers-${i}`}
                value={item.number}
                title={item.label}
                image={item.image.url}
                imageWidth={item.image_width}
                ctaLink={item.link}
              />
            ))}
          </ul>
        </Container>
      </section>
      <section className="AboutUsPage-partners">
        <Title tag="h2" textAlign="center" color="white">
          {intl.formatMessage({ id: 'about.partners' })}
        </Title>
        <Container size="small">
          <ul className="AboutUsPage-partnersList">
            {document.data.partners.map((item, i) => (
              <AboutUsPartnersItem
                key={`partner-${i}`}
                link={item.link}
                logo={item.logo.url}
              />
            ))}
          </ul>
        </Container>
      </section>
      <section className="AboutUsPage-reinsurance">
        <Title tag="h2" textAlign="center">
          {intl.formatMessage({ id: 'about.reinsurance' })}
        </Title>
        <Container size="medium">
          <ul className="AboutUsPage-reinsuranceItems">
            {document.data.plus.map((item, i) => (
              <AboutUsReinsuranceItem
                key={`reinsurance-${i}`}
                title={item.label}
              />
            ))}
          </ul>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery($lang: String) {
    prismicAboutPage(lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        meta_title
        meta_description
        item_1_number
        item_1_label
        item_2_number
        item_2_label
        item_3_number
        item_3_label
        item_4_number
        item_4_label
        offers_numbers {
          image {
            url
          }
          number
          label
          link
          image_width
        }
        partners {
          logo {
            url
          }
          link
        }
        plus {
          label
        }
      }
    }
  }
`;

export default AboutUsPage;
